import React, { useState, useEffect } from "react";
import "./SentimentAnalysisProjects.css";
import { NavLink, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import PopupMessage from "./PopupMessage";
import EmptyTasks from "./EmptyTasks";
import Select from "react-select";
import { getAllUsers } from "./apiService";
import axios from "axios";
import { Users, Plus } from "lucide-react";
import TwoLevelUserManagement from "./TwoLevelUserManagement";

const API_BASE_URL =
  "https://kakarotgenics-django-intermediate.azurewebsites.net/api";

function TwoLevelThematicProjects() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sentimentTasks, setSentimentTasks] = useState([]);
  const [newSentimentTask, setNewSentimentTask] = useState({
    project_id: "",
    project_name: "",
    task_name: "",
    task_description: "",
    users_with_access: [],
  });
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [userCounts, setUserCounts] = useState({});
  const [selectedTask, setSelectedTask] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTwoLevelThematicProjects();
    fetchUsers();
  }, []);

  useEffect(() => {
    // Update to fetch user counts for all tasks when tasks change
    sentimentTasks.forEach((task) => {
      fetchUserCount(task.id);
    });
  }, [sentimentTasks]);

  // In SentimentAnalysisProjects.js, update the fetchUserCount function:
  const fetchUserCount = async (taskId) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new FormData();
      formData.append("task_id", taskId);

      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/view-l2-task-users/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const data = await response.json();

      // Update the counts object with the new count for this specific task
      setUserCounts((prev) => ({
        ...prev,
        [taskId]: data.users_with_access?.length || 0,
      }));
    } catch (error) {
      console.error("Error fetching user count:", error);
      setUserCounts((prev) => ({
        ...prev,
        [taskId]: 0,
      }));
    }
  };

  const fetchTwoLevelThematicProjects = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${API_BASE_URL}/get-level2-thematic-analysis-tasks/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      const data = response.data.tasks;
      console.log("Fetched 2 level Thematic Analysis tasks:", data);
      if (Array.isArray(data)) {
        const formattedTasks = data.map((task) => ({
          id: task.task_id,
          project_id: task.project_id,
          project_name: task.project_name,
          task_name: task.task_name,
          task_description: task.task_description,
          created_by: task.created_by,
          users_with_access: task.users_with_access,
          created_time: task.created_at || new Date().toISOString(),
        }));
        setSentimentTasks(formattedTasks);
      } else {
        console.error("Invalid data structure received:", data);
        setSentimentTasks([]);
      }
      setError(null);
      //   get-level2-thematic-analysis-task-detail/
    } catch (error) {
      console.error(
        "Error fetching 2 level Thematic Analysis projects:",
        error
      );
      setSentimentTasks([]);
      setError(
        "Failed to fetch 2 level Thematic Analysis projects. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const users = await getAllUsers();
      setAllUsers(
        users.map((user) => ({
          value: user.id,
          label: `${user.username} (${user.email})`,
          profilePicture: user.profilePicture,
        }))
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const toggleCreateModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSentimentTask((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserSelect = (selectedOptions) => {
    setNewSentimentTask((prevState) => ({
      ...prevState,
      users_with_access: selectedOptions.map((option) => option.value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("project_id", newSentimentTask.project_id);
      formData.append("project_name", newSentimentTask.project_name);
      formData.append("task_name", newSentimentTask.task_name);
      formData.append("task_description", newSentimentTask.task_description);

      // Append each user_id separately
      newSentimentTask.users_with_access.forEach((userId) => {
        formData.append("users_with_access", userId.toString());
      });

      const response = await axios.post(
        `${API_BASE_URL}/create-level2-thematic-analysis-task/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.task_id) {
        // navigate(`/sentiment-task-details/${response.data.task_id}`);
        setPopupMessage(" 2 level Thematic Analysis Task Created Successfully");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 5000);

        setNewSentimentTask({
          project_id: "",
          project_name: "",
          task_name: "",
          task_description: "",
          users_with_access: [],
        });
        toggleCreateModal();
        fetchTwoLevelThematicProjects(); // Refresh the list
      } else {
        throw new Error(
          "Task created but ID not returned in the expected format"
        );
      }
    } catch (error) {
      console.error("Error creating 2 level Thematic Analysis task:", error);
      setPopupMessage(
        "Failed to create 2 level Thematic Analysis task. Please try again."
      );
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };

  const handleViewProject = (project) => {
    navigate(`/two-level-thematic-analysis-task-details/${project.id}`);
  };

  const handleDeleteClick = (e, task) => {
    e.stopPropagation();
    setTaskToDelete(task);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (taskToDelete) {
      try {
        await axios.post(
          `${API_BASE_URL}/delete-level2-thematic-task/`,
          { task_id: taskToDelete.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setSentimentTasks(
          sentimentTasks.filter((task) => task.id !== taskToDelete.id)
        );
        setPopupMessage("Sentiment Analysis Task deleted successfully");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      } catch (error) {
        console.error("Error deleting task:", error);
        setPopupMessage("Failed to delete task");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      }
    }
    setDeleteModalOpen(false);
    setTaskToDelete(null);
  };

  return (
    <section className="sentiment-analysis-projects-section">
      <Menu />
      <nav className="navbar navbar-light bg-body-tertiary custom-navbar">
        <div className="container-fluid">
          <NavLink className="navbar-brand gradient-text" to="#">
            Tasks
          </NavLink>
        </div>
      </nav>
      <button
        className="projects-btn-hover color-3"
        onClick={toggleCreateModal}
      >
        Create Task
      </button>
      {isLoading ? (
        <div className="text-center" style={{ marginTop: "300px" }}>
          <div
            className="spinner-border"
            role="status"
            style={{
              color: "rgb(105, 0, 225)",
              width: "2rem",
              height: "2rem",
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : sentimentTasks.length > 0 ? (
        <div className="sentiment-analysis-projects">
          {sentimentTasks.map((task) => (
            <div key={task.id} className="card">
              <div className="card-content">
                <div
                  className="delete-icon"
                  onClick={(e) => handleDeleteClick(e, task)}
                >
                  <i className="bi bi-trash"></i>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <button
                    onClick={() => {
                      setSelectedTask(task);
                      setShowParticipantsModal(true);
                    }}
                    className="btn btn-link border-0 p-0 d-flex align-items-center position-relative"
                    title="View and add participants"
                  >
                    <Users size={18} className="text-black" />
                    <Plus
                      size={12}
                      className="text-black position-absolute"
                      style={{ top: "-4px", right: "-4px" }}
                    />
                  </button>
                  <span className="text-black ms-1">
                    {userCounts[task.id] || 0}
                  </span>
                </div>
                <div onClick={() => handleViewProject(task)}>
                  <h6>{task.task_name}</h6>
                  <p>{task.task_description}</p>
                  <p>
                    <small>Project: {task.project_name}</small>
                  </p>
                  <p>
                    <small>Created by: {task.created_by}</small>
                  </p>
                  <p>
                    <small>
                      Created on: {new Date(task.created_time).toLocaleString()}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyTasks />
      )}
      <TwoLevelUserManagement
        isOpen={showParticipantsModal}
        onClose={() => {
          setShowParticipantsModal(false);
          if (selectedTask) {
            fetchUserCount(selectedTask.id); // Pass selected task ID
          }
          setSelectedTask(null); // Clear selected task when closing
        }}
        taskId={selectedTask?.id} // Pass the selected task's ID
      />

      <div
        className={`moduleModal modal fade ${isModalOpen ? "show" : ""}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!isModalOpen}
      >
        <div className="moduleModalDialog modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New Task
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={toggleCreateModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="project_id" className="col-form-label">
                    Project ID:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="project_id"
                    name="project_id"
                    value={newSentimentTask.project_id}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="project_name" className="col-form-label">
                    Project Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="project_name"
                    name="project_name"
                    value={newSentimentTask.project_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="task_name" className="col-form-label">
                    Task Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="task_name"
                    name="task_name"
                    value={newSentimentTask.task_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="task_description" className="col-form-label">
                    Task Description:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="task_description"
                    name="task_description"
                    value={newSentimentTask.task_description}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="users" className="form-label">
                    Select Users:
                  </label>
                  <Select
                    isMulti
                    name="users"
                    options={allUsers}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleUserSelect}
                    placeholder="Select users..."
                    noOptionsMessage={() => "No users found"}
                    formatOptionLabel={(option) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            option.profilePicture
                              ? `data:image/png;base64,${option.profilePicture}`
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                          }
                          alt="Profile"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            marginRight: "10px",
                            objectFit: "cover",
                          }}
                        />
                        <div>
                          <div>{option.label.split(" (")[0]}</div>
                          <div style={{ fontSize: "0.8em", color: "#666" }}>
                            {option.label.split(" (")[1].slice(0, -1)}
                          </div>
                        </div>
                      </div>
                    )}
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        minHeight: "50px",
                      }),
                    }}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-cancel-projects"
                    onClick={toggleCreateModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn-create-project">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {deleteModalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <h5>Delete Task</h5>
            <p>Are you sure you want to delete this task?</p>
            <p className="warning">This action cannot be undone.</p>
            <div className="button-container">
              <button
                className="cancel-btn"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button className="delete-btn" onClick={handleDeleteConfirm}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </section>
  );
}

export default TwoLevelThematicProjects;
