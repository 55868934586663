import axios from "axios";

const API_URL =
  "https://kakarotgenics-django-intermediate.azurewebsites.net/api/";

export const getCsatCostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-azure-csat/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchCsatCostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-azure-csat/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPiiCostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-pii-detection/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchPiiCostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-pii-detection/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getsentimentalCostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-sentimental/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchsentimentalCostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-sentimental/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getlevel1CostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-level1-thematic/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchlevel1CostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-level1-thematic/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getlevel2CostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-level2-thematic/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchlevel2CostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-level2-thematic/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getlevel3CostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-level3-thematic/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchlevel3CostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-level3-thematic/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPiigenaiCostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-pii-genai/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchPiigenaiCostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-pii-genai/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGibberishdetectionCostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-gibberish-detection/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchGibberishdetectionCostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-gibberish-detection/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTextCostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost-text-translation/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetchTextCostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}cost-estimation-report-text-translation/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
