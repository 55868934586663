import React from "react";
import "./CostEstimationReport.css";

import { useLocation } from "react-router-dom";

const CostEstimationReport = ({ report, error, isLoading }) => {
  if (isLoading) {
    return (
      <div className="text-center" style={{ marginTop: "100px" }}>
        <div
          className="spinner-border"
          role="status"
          style={{
            color: "rgb(105, 0, 225)",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!report || !report.data) {
    return <p>No cost estimation report available.</p>;
  }

  const { total_number_of_verbatims, final_total_cost, final_total_cost_inr } =
    report.data;

  return (
    <div className="cost-estimation-report">
      <h5 style={{ marginBottom: "30px" }}>Cost Estimation Report</h5>

      <section style={{ marginBottom: "30px" }}>
        <h6>1. Project Information</h6>
        <ul>
          <li>
            <strong>Project Name:</strong> {"Cloud Native Project"}
          </li>
          <li>
            <strong>Description:</strong>
            <br />
            The project utilizes AI with LLM, Keyword Mapping, and NLP
            techniques to extract themes from a set of{" "}
            {total_number_of_verbatims || "N/A"} verbatims.
          </li>
        </ul>
      </section>

      <section style={{ marginBottom: "30px" }}>
        <h6>2. Verbatims Summary</h6>
        <ul>
          <li>
            <strong>Total Verbatims Processed:</strong>{" "}
            {total_number_of_verbatims || "N/A"}
          </li>
        </ul>
      </section>

      <section
        style={{ width: "100%", maxWidth: "600px", marginBottom: "30px" }}
      >
        <h6>3. Estimated Cost</h6>
        <div style={{ width: "100%", height: "100px", overflowY: "auto" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "10px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "50%",
                    padding: "10px",
                    textAlign: "center",
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Total Verbatims
                </th>
                <th
                  style={{
                    width: "50%",
                    padding: "10px",
                    textAlign: "center",
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Cost (USD)
                </th>
                {/* <th style={{ width: '25%', padding: '10px', textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>Cost (INR)</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {total_number_of_verbatims || "N/A"}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  ${final_total_cost?.toFixed(2) || "N/A"}
                </td>
                {/* <td style={{ padding: '10px', border: '1px solid #ddd' }}>₹{final_total_cost_inr?.value?.toFixed(2) || 'N/A'}</td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section style={{ marginBottom: "30px" }}>
        <h6>4. Cost Factors</h6>
        <ul>
          <li>
            <strong>AI Model:</strong> Costs driven by LLM usage for processing.
          </li>
          <li>
            <strong>Text Complexity:</strong> More complex verbatims require
            deeper analysis.
          </li>
          <li>
            <strong>Text Length:</strong> Longer texts demand more resources.
          </li>
          <li>
            <strong>NLP Techniques:</strong> Additional techniques like Keyword
            Mapping and Thematic Extraction contribute to cost.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default CostEstimationReport;

// , margin: '0 auto'
