import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OutputFileTab.css";

const PiiGenaiOutputTab = ({
  isTaskCompleted,
  projectId,
  handleDownloadClick,
}) => {
  const [fileContent, setFileContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);

  useEffect(() => {
    if (isTaskCompleted && projectId) {
      fetchFileContent(currentPage);
    }
  }, [isTaskCompleted, projectId, currentPage]);

  const fetchFileContent = async (page) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://kakarotgenics-ai-backend.azurewebsites.net/file-output-view/PII_Gen_AI_Analysis/${projectId}/?page=${page}`
      );
      setFileContent(response.data.results);
      setNextPage(response.data.next);
      setPreviousPage(response.data.previous);
    } catch (err) {
      setError("Failed to fetch file content. Please try again.");
      console.error("Error fetching file content:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (direction) => {
    let newPage;
    if (direction === "next" && nextPage) {
      newPage = currentPage + 1;
    } else if (direction === "previous" && previousPage) {
      newPage = currentPage - 1;
    }

    if (newPage && newPage > 0) {
      setCurrentPage(newPage);
    }
  };

  const getColumnStyle = (key) => {
    if (!fileContent || fileContent.length === 0) return {};

    // Get maximum content length for this column
    const maxLength = Math.max(
      key.length,
      ...fileContent.map((row) => String(row[key] || "").length)
    );

    // Calculate minimum width based on content (approximately 8px per character)
    const minWidth = Math.max(100, maxLength * 8); // Minimum 100px width
    const maxWidth = Math.min(500, minWidth); // Maximum 500px width

    return {
      minWidth: `${minWidth}px`,
      maxWidth: `${maxWidth}px`,
      whiteSpace: "pre-wrap", // Preserve whitespace and wrap
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
  };

  const renderFileContent = () => {
    if (!fileContent || fileContent.length === 0)
      return <p>No data available.</p>;

    const columnKeys = Object.keys(fileContent[0]);

    return (
      <div
        className="table-wrapper"
        style={{ overflowX: "auto", width: "100%" }}
      >
        <table
          className="output-table"
          style={{
            tableLayout: "auto",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              {columnKeys.map((key) => (
                <th
                  key={key}
                  style={{
                    ...getColumnStyle(key),
                    padding: "12px",
                    backgroundColor: "#f8f9fa",
                    borderBottom: "2px solid #dee2e6",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fileContent.map((row, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#ffffff" : "#f8f9fa",
                }}
              >
                {columnKeys.map((key, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{
                      ...getColumnStyle(key),
                      padding: "12px",
                      borderBottom: "1px solid #dee2e6",
                      wordBreak: "break-word",
                    }}
                  >
                    {row[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="output-file-container">
      <h4 style={{ marginBottom: "40px" }}>Output File</h4>
      {isTaskCompleted ? (
        <>
          {isLoading ? (
            <div className="text-center" style={{ marginTop: "100px" }}>
              <div
                className="spinner-border"
                role="status"
                style={{
                  color: "rgb(105, 0, 225)",
                }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : error ? (
            <p className="text-danger">{error}</p>
          ) : fileContent ? (
            <div style={{ maxWidth: "100%", overflowX: "hidden" }}>
              <div className="file-content-container">
                {renderFileContent()}
              </div>
              <div className="pagination-controls">
                <button
                  className="btn btn-secondary"
                  onClick={() => handlePageChange("previous")}
                  disabled={!previousPage}
                >
                  Previous
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => handlePageChange("next")}
                  disabled={!nextPage}
                  style={{ marginLeft: "10px" }}
                >
                  Next
                </button>
              </div>
              <div
                className="caution-message"
                style={{
                  marginTop: "20px",
                  padding: "10px",
                  backgroundColor: "#fff3cd",
                  borderRadius: "5px",
                }}
              >
                <p>
                  <strong>Note:</strong> This view shows a limited portion of
                  the output file. For a complete and detailed view, please
                  download the file.
                </p>
              </div>
            </div>
          ) : (
            <p>No file content available.</p>
          )}
        </>
      ) : (
        <p>
          Analysis not completed yet. Run the analysis to generate the output
          file.
        </p>
      )}
    </div>
  );
};

export default PiiGenaiOutputTab;
