// import React, { useState, useEffect } from "react";
// import ReactApexChart from "react-apexcharts";
// import axios from "axios";

// const TextGraphical = ({ taskId, isTaskCompleted }) => {
//   const [chartData, setChartData] = useState(null);
//   const [pieChartData, setPieChartData] = useState(null);
//   const [barChartData, setBarChartData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (isTaskCompleted && taskId) {
//       fetchAnalysisData();
//       fetchBarChartData();
//     }
//   }, [taskId, isTaskCompleted]);

//   const fetchBarChartData = async () => {
//     try {
//       const token = localStorage.getItem("accessToken");
//       if (!token) {
//         throw new Error("Authentication token not found");
//       }

//       const response = await axios.post(
//         "https://kakarotgenics-django-intermediate.azurewebsites.net/api/bar-chart-text-translation/",
//         { task_id: taskId },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       // Validate response data
//       if (!response?.data?.data || !Array.isArray(response.data.data)) {
//         throw new Error("Invalid bar chart data format received");
//       }

//       const data = response.data.data;
//       if (data.length === 0) {
//         setBarChartData(null);
//         return;
//       }

//       const processedData = {
//         labels: data.map((item) => item.language || "Unknown"),
//         values: data.map((item) => Number(item.count) || 0),
//         title: response.data.chart_title || "Frequency of Themes",
//       };

//       setBarChartData({
//         series: [
//           {
//             name: "Theme Count",
//             data: processedData.values,
//           },
//         ],
//         options: {
//           chart: {
//             type: "bar",
//             height: 240,
//             toolbar: {
//               show: true,
//               tools: {
//                 download: true,
//                 selection: false,
//                 zoom: false,
//                 zoomin: false,
//                 zoomout: false,
//                 pan: false,
//                 reset: false,
//               },
//             },
//           },
//           plotOptions: {
//             bar: {
//               borderRadius: 3,
//               horizontal: false,
//               columnWidth: "60%",
//               distributed: true,
//             },
//           },
//           colors: ["#4B88FD", "#FF9900", "#34A853", "#818CF8"],
//           dataLabels: {
//             enabled: true,
//             formatter: (val) => val?.toLocaleString() || "0",
//             style: {
//               fontSize: "11px",
//             },
//           },
//           legend: {
//             show: false,
//           },
//           xaxis: {
//             categories: processedData.labels,
//             labels: {
//               style: {
//                 fontSize: "11px",
//               },
//             },
//           },
//           yaxis: {
//             title: {
//               text: "Count",
//               style: {
//                 fontSize: "12px",
//               },
//             },
//             labels: {
//               style: {
//                 fontSize: "11px",
//               },
//               formatter: (val) => Math.round(val)?.toString() || "0",
//             },
//           },
//           title: {
//             text: processedData.title,
//             align: "center",
//             style: {
//               fontSize: "14px",
//             },
//           },
//         },
//       });
//     } catch (err) {
//       console.error("Error fetching bar chart data:", err);
//       setError(err.message || "Failed to fetch bar chart data");
//       setBarChartData(null);
//     }
//   };

//   const fetchAnalysisData = async () => {
//     try {
//       setIsLoading(true);
//       const token = localStorage.getItem("accessToken");
//       if (!token) {
//         throw new Error("Authentication token not found");
//       }

//       const response = await axios.post(
//         "https://kakarotgenics-django-intermediate.azurewebsites.net/api/pie-chart-text-translation/",
//         { task_id: taskId },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       // Validate response data
//       if (!response?.data?.data || !Array.isArray(response.data.data)) {
//         throw new Error("Invalid pie chart data format received");
//       }

//       const data = response.data.data;
//       if (data.length === 0) {
//         setPieChartData(null);
//         return;
//       }

//       const processedData = {
//         labels: data.map((item) => item.score_range || "Unknown"),
//         values: data.map((item) => Number(item.count) || 0),
//         title: response.data.chart_title || "Brand Distribution",
//       };

//       // Ensure we have valid data before setting the pie chart
//       if (processedData.values.some((value) => value > 0)) {
//         setPieChartData({
//           series: processedData.values,
//           options: {
//             chart: {
//               type: "pie",
//               height: 280,
//             },
//             labels: processedData.labels,
//             colors: ["#4B88FD", "#FF9900", "#34A853", "#818CF8"],
//             legend: {
//               position: "bottom",
//               fontSize: "11px",
//               formatter: function (seriesName, opts) {
//                 const value = opts.w.globals.series[opts.seriesIndex];
//                 return `${seriesName}: ${value?.toLocaleString() || 0}`;
//               },
//             },
//             title: {
//               text: response.data.chart_title || "Regional Distribution",
//               align: "center",
//               style: {
//                 fontSize: "14px",
//               },
//             },
//             dataLabels: {
//               formatter: function (val) {
//                 return val?.toFixed(1) + "%" || "0%";
//               },
//               style: {
//                 fontSize: "11px",
//               },
//             },
//           },
//         });
//       } else {
//         setPieChartData(null);
//       }
//     } catch (err) {
//       console.error("Error fetching pie chart data:", err);
//       setError(err.message || "Failed to fetch analysis data");
//       setPieChartData(null);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   if (!isTaskCompleted) {
//     return (
//       <div className="alert alert-info m-3">
//         Please complete the analysis to view graphical insights.
//       </div>
//     );
//   }

//   if (isLoading) {
//     return (
//       <div className="d-flex justify-content-center m-3">
//         <div className="spinner-border text-primary" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       </div>
//     );
//   }

//   if (error) {
//     return <div className="alert alert-danger m-3">{error}</div>;
//   }

//   return (
//     <div className="container-fluid">
//       <div className="row g-3">
//         {barChartData && (
//           <div className="col-md-6">
//             <div className="card shadow-sm">
//               <div className="card-body p-2">
//                 <ReactApexChart
//                   options={barChartData.options}
//                   series={barChartData.series}
//                   type="bar"
//                   height={280}
//                 />
//               </div>
//             </div>
//           </div>
//         )}

//         {pieChartData && (
//           <div className="col-md-6">
//             <div className="card shadow-sm">
//               <div className="card-body p-2">
//                 <ReactApexChart
//                   options={pieChartData.options}
//                   series={pieChartData.series}
//                   type="pie"
//                   height={250}
//                 />
//               </div>
//             </div>
//           </div>
//         )}

//         {pieChartData && (
//           <div className="col-12">
//             <div className="card shadow-sm">
//               <div className="card-header py-2">
//                 <h6 className="card-title mb-0">Distribution Summary</h6>
//               </div>
//               <div className="card-body p-2">
//                 <ul className="list-group list-group-flush">
//                   {pieChartData.options.labels.map((label, index) => {
//                     const total = pieChartData.series.reduce(
//                       (a, b) => a + b,
//                       0
//                     );
//                     const value = pieChartData.series[index];
//                     const percentage = total > 0 ? (value / total) * 100 : 0;

//                     return (
//                       <li key={index} className="list-group-item py-1">
//                         <small>
//                           <strong>{label}:</strong>{" "}
//                           {value?.toLocaleString() || 0} (
//                           {percentage.toFixed(1)}%)
//                         </small>
//                       </li>
//                     );
//                   })}
//                   <li className="list-group-item py-1">
//                     <small>
//                       <strong>Total:</strong>{" "}
//                       {pieChartData.series
//                         .reduce((a, b) => a + b, 0)
//                         .toLocaleString()}
//                     </small>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         )}

//         {!barChartData && !pieChartData && (
//           <div className="col-12">
//             <div className="alert alert-warning m-3">
//               No analysis data available to display.
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default TextGraphical;

import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

const TextGraphical = ({ taskId }) => {
  const [chartData, setChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (taskId) {
      fetchAnalysisData();
      fetchBarChartData();
    }
  }, [taskId]);

  const fetchBarChartData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Authentication token not found");
      }

      const response = await axios.post(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/bar-chart-text-translation/",
        { task_id: taskId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response?.data?.data || !Array.isArray(response.data.data)) {
        throw new Error("Invalid bar chart data format received");
      }

      const data = response.data.data;
      if (data.length === 0) {
        setBarChartData(null);
        return;
      }

      const processedData = {
        labels: data.map((item) => item.language || "Unknown"),
        values: data.map((item) => Number(item.count) || 0),
        title: response.data.chart_title || "Frequency of Themes",
      };

      setBarChartData({
        series: [
          {
            name: "Theme Count",
            data: processedData.values,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 240,
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
              horizontal: false,
              columnWidth: "60%",
              distributed: true,
            },
          },
          colors: ["#4B88FD", "#FF9900", "#34A853", "#818CF8"],
          dataLabels: {
            enabled: true,
            formatter: (val) => val?.toLocaleString() || "0",
            style: {
              fontSize: "11px",
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: processedData.labels,
            labels: {
              style: {
                fontSize: "11px",
              },
            },
          },
          yaxis: {
            title: {
              text: "Count",
              style: {
                fontSize: "12px",
              },
            },
            labels: {
              style: {
                fontSize: "11px",
              },
              formatter: (val) => Math.round(val)?.toString() || "0",
            },
          },
          title: {
            text: processedData.title,
            align: "center",
            style: {
              fontSize: "14px",
            },
          },
        },
      });
    } catch (err) {
      console.error("Error fetching bar chart data:", err);
      setError(err.message || "Failed to fetch bar chart data");
      setBarChartData(null);
    }
  };

  const fetchAnalysisData = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Authentication token not found");
      }

      const response = await axios.post(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/pie-chart-text-translation/",
        { task_id: taskId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response?.data?.data || !Array.isArray(response.data.data)) {
        throw new Error("Invalid pie chart data format received");
      }

      const data = response.data.data;
      if (data.length === 0) {
        setPieChartData(null);
        return;
      }

      const processedData = {
        labels: data.map((item) => item.score_range || "Unknown"),
        values: data.map((item) => Number(item.count) || 0),
        title: response.data.chart_title || "Brand Distribution",
      };

      if (processedData.values.some((value) => value > 0)) {
        setPieChartData({
          series: processedData.values,
          options: {
            chart: {
              type: "pie",
              height: 280,
            },
            labels: processedData.labels,
            colors: ["#4B88FD", "#FF9900", "#34A853", "#818CF8"],
            legend: {
              position: "bottom",
              fontSize: "11px",
              formatter: function (seriesName, opts) {
                const value = opts.w.globals.series[opts.seriesIndex];
                return `${seriesName}: ${value?.toLocaleString() || 0}`;
              },
            },
            title: {
              text: response.data.chart_title || "Regional Distribution",
              align: "center",
              style: {
                fontSize: "14px",
              },
            },
            dataLabels: {
              formatter: function (val) {
                return val?.toFixed(1) + "%" || "0%";
              },
              style: {
                fontSize: "11px",
              },
            },
          },
        });
      } else {
        setPieChartData(null);
      }
    } catch (err) {
      console.error("Error fetching pie chart data:", err);
      setError(err.message || "Failed to fetch analysis data");
      setPieChartData(null);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center m-3">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger m-3">{error}</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row g-3">
        {barChartData && (
          <div className="col-md-6">
            <div className="card shadow-sm">
              <div className="card-body p-2">
                <ReactApexChart
                  options={barChartData.options}
                  series={barChartData.series}
                  type="bar"
                  height={280}
                />
              </div>
            </div>
          </div>
        )}

        {pieChartData && (
          <div className="col-md-6">
            <div className="card shadow-sm">
              <div className="card-body p-2">
                <ReactApexChart
                  options={pieChartData.options}
                  series={pieChartData.series}
                  type="pie"
                  height={250}
                />
              </div>
            </div>
          </div>
        )}

        {pieChartData && (
          <div className="col-12">
            <div className="card shadow-sm">
              <div className="card-header py-2">
                <h6 className="card-title mb-0">Distribution Summary</h6>
              </div>
              <div className="card-body p-2">
                <ul className="list-group list-group-flush">
                  {pieChartData.options.labels.map((label, index) => {
                    const total = pieChartData.series.reduce(
                      (a, b) => a + b,
                      0
                    );
                    const value = pieChartData.series[index];
                    const percentage = total > 0 ? (value / total) * 100 : 0;

                    return (
                      <li key={index} className="list-group-item py-1">
                        <small>
                          <strong>{label}:</strong>{" "}
                          {value?.toLocaleString() || 0} (
                          {percentage.toFixed(1)}%)
                        </small>
                      </li>
                    );
                  })}
                  <li className="list-group-item py-1">
                    <small>
                      <strong>Total:</strong>{" "}
                      {pieChartData.series
                        .reduce((a, b) => a + b, 0)
                        .toLocaleString()}
                    </small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {!barChartData && !pieChartData && (
          <div className="col-12">
            <div className="alert alert-warning m-3">
              No analysis data available to display.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextGraphical;
