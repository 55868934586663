import React, { useState, useEffect } from "react";
import "./PiiDetectorProjects.css";
import { NavLink, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import ViewProject from "./ViewProject";
import {
  getPiiProjects,
  createPiiDetectionTask,
  updatePiiProject,
  deletePiiDetectionTask,
} from "./apiService";
import PopupMessage from "./PopupMessage"; // Import the PopupMessage component
import EmptyTasks from "./EmptyTasks";
import PiiDetectorUsers from "./PiiDetectorUsers";
import { Users, Plus } from "lucide-react";

function PiiDetectorProjects() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [piiTasks, setPiiTasks] = useState([]);
  const [newPiiTask, setNewPiiTask] = useState({ name: "", description: "" });
  const [viewPiiProject, setViewPiiProject] = useState(null);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPiiProjects();
  }, []);

  const fetchPiiProjects = async () => {
    try {
      setIsLoading(true);
      const data = await getPiiProjects();
      console.log("Fetched PII tasks:", data);
      const formattedTasks = data.map((task) => ({
        id: task.task_id,
        task_name: task.task_name,
        task_description: task.task_description,
        created_by: task.created_by,
        created_time: task.created_at || new Date().toISOString(),
      }));
      setPiiTasks(formattedTasks);
      setError(null);
    } catch (error) {
      console.error("Error fetching PII projects:", error);
      setPiiTasks([]);
      setError("Failed to fetch PII projects. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserCount = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/pii-detection-users/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setUserCount(data.users?.length || 0);
    } catch (error) {
      console.error("Error fetching user count:", error);
      setUserCount(0);
    }
  };

  // Add useEffect to fetch user count when component mounts
  useEffect(() => {
    fetchUserCount();
  }, []);

  const toggleCreateModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewPiiTask({ ...newPiiTask, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const taskData = {
        task_name: newPiiTask.name,
        task_description: newPiiTask.description,
      };

      const response = await createPiiDetectionTask(taskData);

      // Check if the response contains the new task's ID
      if (response && response.task_id) {
        // Navigate to the new task's details page
        navigate(`/pii-task-details/${response.task_id}`);
      } else {
        throw new Error("Task created but ID not returned");
      }

      setPopupMessage("PII Detection Task Created Successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);

      setNewPiiTask({ name: "", description: "" });
      toggleCreateModal();
    } catch (error) {
      console.error("Error creating PII detection task:", error);
      setPopupMessage("Failed to create PII detection task.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };

  const handleViewProject = (project) => {
    navigate(`/pii-task-details/${project.id}`);
  };

  const closeViewProjectModal = () => {
    setViewPiiProject(null);
  };

  const handleUpdateProject = async (updatedProject) => {
    try {
      const updatedData = await updatePiiProject(
        viewPiiProject.id,
        updatedProject
      );

      const updatedProjectWithDetails = {
        ...viewPiiProject,
        ...updatedData,
        ...updatedProject,
      };

      const updatedProjects = piiTasks.map((project) =>
        project.id === viewPiiProject.id ? updatedProjectWithDetails : project
      );

      setPiiTasks([...updatedProjects]);

      setPopupMessage("PII Project Updated Successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 1000);
      setViewPiiProject(null);
    } catch (error) {
      console.error("Error updating PII project:", error);
      setPopupMessage("Failed to update PII project.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 1000);
    }
  };

  const handleDeleteClick = (e, task) => {
    e.stopPropagation(); // Prevent triggering the handleViewProject
    setTaskToDelete(task);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (taskToDelete) {
      try {
        await deletePiiDetectionTask(taskToDelete.id);
        setPiiTasks(piiTasks.filter((task) => task.id !== taskToDelete.id));
        setPopupMessage("PII Detection Task deleted successfully");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      } catch (error) {
        console.error("Error deleting task:", error);
        setPopupMessage("Failed to delete task");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      }
    }
    setDeleteModalOpen(false);
    setTaskToDelete(null);
  };

  return (
    <section className="pii-detector-projects-section">
      <Menu />
      <nav className="navbar navbar-light bg-body-tertiary custom-navbar">
        <div className="container-fluid">
          <div className="d-flex align-items-center gap-1">
            <NavLink className="navbar-brand gradient-text m-0" to="#">
              Tasks
            </NavLink>
            <div className="d-flex align-items-center gap-0">
              <button
                onClick={() => setShowParticipantsModal(true)}
                className="btn btn-link border-0 p-0 d-flex align-items-center position-relative"
                title="View and add participants"
              >
                <Users size={18} className="text-black" />
                <Plus
                  size={12}
                  className="text-black position-absolute"
                  style={{ top: "-4px", right: "-4px" }}
                />
              </button>
              <span className="text-black ms-1">{userCount}</span>
            </div>
          </div>
          <PiiDetectorUsers
            isOpen={showParticipantsModal}
            onClose={() => {
              setShowParticipantsModal(false);
              fetchUserCount(); // Refresh count when modal closes
            }}
          />
        </div>
      </nav>
      <button
        className="projects-btn-hover color-3"
        onClick={toggleCreateModal}
      >
        Create Task
      </button>
      {isLoading ? (
        <div className="text-center" style={{ marginTop: "300px" }}>
          <div
            className="spinner-border"
            role="status"
            style={{
              color: "rgb(105, 0, 225)",
              width: "2rem",
              height: "2rem",
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : piiTasks.length > 0 ? (
        <div className="pii-detector-projects">
          {piiTasks.map((task) => (
            <div key={task.id} className="card">
              <div className="card-content">
                <div
                  className="delete-icon"
                  onClick={(e) => handleDeleteClick(e, task)}
                >
                  <i className="bi bi-trash"></i>
                </div>
                <div onClick={() => handleViewProject(task)}>
                  <h6>{task.task_name}</h6>
                  <p>{task.task_description}</p>
                  <p>
                    <small>Created by: {task.created_by}</small>
                  </p>
                  <p>
                    <small>
                      Created on: {new Date(task.created_time).toLocaleString()}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyTasks />
      )}

      <div
        className={`modal-projects modal fade ${isModalOpen ? "show" : ""}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!isModalOpen}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New Task
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={toggleCreateModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Task name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={newPiiTask.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="col-form-label">
                    Task Description :
                  </label>
                  <textarea
                    className="form-control"
                    id="description"
                    value={newPiiTask.description}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-cancel-projects"
                    onClick={toggleCreateModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn-create-project">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {viewPiiProject && (
        <ViewProject
          project={viewPiiProject}
          isOpen={Boolean(viewPiiProject)}
          onClose={closeViewProjectModal}
          onUpdate={handleUpdateProject}
        />
      )}

      {deleteModalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <h5>Delete Task</h5>
            <p>Are you sure you want to delete this task?</p>
            <p className="warning">This action cannot be undone.</p>
            <div className="button-container">
              <button
                className="cancel-btn"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button className="delete-btn" onClick={handleDeleteConfirm}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </section>
  );
}

export default PiiDetectorProjects;
