import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  DashboardOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Menu.css";

export default function Menu() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 425);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuItems = [
    { to: "/profile", title: "Profile", icon: <UserOutlined /> },
    { to: "/users", title: "Users", icon: <TeamOutlined /> },
    { to: "/modules", title: "Projects", icon: <ProjectOutlined /> },
    { to: "/generic-modals", title: "Generic", icon: <ProjectOutlined /> },
    { to: "/dashboard", title: "Dashboard", icon: <DashboardOutlined /> },
    { to: "/login", title: "Logout", icon: <LogoutOutlined /> },
  ];

  return (
    <div className="sidebar">
      <ul className="navbar-nav">
        {menuItems.map((item, index) => (
          <li className="nav-item" key={index}>
            <NavLink
              className={({ isActive }) =>
                `nav-link ${isActive ? "active" : ""}`
              }
              to={item.to}
              title={isMobile ? undefined : item.title}
            >
              {item.icon}
            </NavLink>
            <small className="menu-text">{item.title}</small>
          </li>
        ))}
      </ul>
    </div>
  );
}
