import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Register.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { registerUser } from "./apiService";
import PopupMessage from "./PopupMessage";
import BackGround from "../common/BackGround";

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Add this state
  const [isRegistering, setIsRegistering] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    const checkResponsive = () => {
      setIsMobile(window.innerWidth <= 425);
      setIsTablet(window.innerWidth <= 768 && window.innerWidth > 425);
    };

    checkResponsive();
    window.addEventListener("resize", checkResponsive);
    return () => window.removeEventListener("resize", checkResponsive);
  }, []);

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSecretKeyChange = (e) => {
    setSecretKey(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    setIsRegistering(true);
    try {
      const userData = {
        username: username,
        email: email,
        password1: password,
        password2: confirmPassword,
        secret_key_input: secretKey,
      };

      const response = await registerUser(userData);

      if (response.status === "success") {
        localStorage.setItem("userName", username);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("isAdmin", response.data.is_admin.toString());

        setPopupMessage("Registration successful!");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 5000);

        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        setErrorMessage(response.message);
        setTimeout(() => setErrorMessage(""), 5000); // Update the error message
      }
    } catch (error) {
      console.error("Error registering user:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message); // Update the error message
      } else {
        setErrorMessage("Registration Failed. Please try again."); // Update the error message
      }
      setTimeout(() => setErrorMessage(""), 5000);
    } finally {
      setIsRegistering(false); // Set loading state back to false when registration process ends
    }
  };

  const showPassHandle = () => {
    setShowPass(!showPass);
  };

  return (
    <div className="su-cnt d-flex justify-content-end">
      {!isMobile && !isTablet && <BackGround />}
      <div
        className="su-starth h-100 d-flex align-items-center"
        style={{ width: isMobile || isTablet ? "100%" : "50%" }}
      >
        <div className="su-start-cnt">
          <div className="su-start-title">
            <span className="su-start-title-txt brand-name">TXTSense</span>
            <p className="su-start-hdng mt-3 mb-5 brand-name-text">
              Sign Up and start Exploring !!
            </p>
          </div>
          {errorMessage && ( // Conditionally render the error message
            <div className="error-message">
              <i className="bi bi-exclamation-triangle error-icon"></i>
              {errorMessage}
            </div>
          )}
          <form onSubmit={handleSubmit} className="su-start-form">
            <div className="su-start-form-group">
              <label htmlFor="userName" className="lg-label">
                Username
              </label>
              <input
                type="text"
                id="userName"
                className="w-100 lg-inp"
                name="userName"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="su-start-form-group">
              <label htmlFor="email" className="lg-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-100 lg-inp"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="su-start-form-group">
              <label htmlFor="password" className="lg-label">
                Password
              </label>
              <div className="password-input-container">
                <input
                  type={showPass ? "text" : "password"}
                  id="password"
                  className="w-100 lg-inp"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <div className="eye-cnt" onClick={showPassHandle}>
                  {showPass ? (
                    <i className="bi bi-eye-slash"></i>
                  ) : (
                    <i className="bi bi-eye"></i>
                  )}
                </div>
              </div>
            </div>
            <div className="su-start-form-group">
              <label htmlFor="confirmPassword" className="lg-label">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="w-100 lg-inp"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              {passwordError && <p className="error-msg">{passwordError}</p>}
            </div>
            <div className="su-start-form-group">
              <label htmlFor="secretKey" className="lg-label">
                Secret Key
              </label>
              <input
                type="text"
                id="secretKey"
                className="w-100 lg-inp"
                name="secretKey"
                value={secretKey}
                onChange={handleSecretKeyChange}
                required
              />
            </div>
            <div
              className="su-start-form-group"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <button
                type="submit"
                className="btn-btn-register"
                disabled={isRegistering}
              >
                {isRegistering ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Sign Up"
                )}
              </button>
              <p className="terms-text" style={{ marginTop: "10px" }}>
                By signing up, you agree to our{" "}
                <NavLink to="/terms" className="purple-link">
                  Terms of Use
                </NavLink>{" "}
                and{" "}
                <NavLink to="/privacy" className="purple-link">
                  Privacy Policy
                </NavLink>
                .
              </p>
              <p style={{ marginTop: "10px" }}>
                Already have an account?{" "}
                <NavLink to="/login" className="purple-link">
                  Log in
                </NavLink>
              </p>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}

export default Register;
