import axios from "axios";

// Base URL for the API
const API_URL = "https://kakarotgenics-ai-backend.azurewebsites.net/";
//  ws://kakarotgenics-instance.centralus.azurecontainer.io:8000/ws/task-status/<project id>/

// const API_URL_WS = " ws://kakarotgenics-instance.centralus.azurecontainer.io:8000/"
// // Function to get project status by project ID
// const getProjectStatus = async (projectId) => {
//   try {
//     const response = await axios.get(`${API_URL_WS}task-status/${projectId}/`);
//     return response.data; // Return the response data
//   } catch (error) {
//     throw error; // Throw error to handle it in the calling function
//   }
// };

const webSocketConnection = (cloudNativeTaskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/cloud-native-task-status/${cloudNativeTaskId}/`
  );
  // ws://kakarotgenics-instance.centralus.azurecontainer.io:8000/ws/task-status/<project id>/

  socket.onopen = (event) => {
    console.log("WebSocket connection established", event);
  };

  socket.onmessage = (event) => {
    let percentData = JSON.parse(event.data);

    console.log("WebSocket message received", percentData);
    onMessage(JSON.parse(event.data));
  };

  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log("WebSocket connection closed", event.code, event.reason);
    // Implement reconnection logic here if needed
  };

  return socket;
};

const downloadCloudNativeOutput = async (projectId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-cloud-native-output/${projectId}/`,
      {
        responseType: "blob", // Important for handling file downloads
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Set the content type for the response
        },
      }
    );

    // Create a blob from the response data
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `output-${projectId}.xlsx`); // Set the desired file name
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the object URL
    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error; // Throw error to handle it in the calling function
  }
};

const piiwebSocketConnection = (taskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/azure-pii-task-status/${taskId}/`
  );

  socket.onopen = (event) => {
    console.log("PII WebSocket connection established for task:", taskId);
  };

  socket.onmessage = (event) => {
    console.log("PII WebSocket message received:", event.data);
    try {
      const data = JSON.parse(event.data);
      console.log("Parsed WebSocket data:", data);
      onMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      onError(error);
    }
  };

  socket.onerror = (error) => {
    console.error("PII WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log(
      "PII WebSocket connection closed for task:",
      taskId,
      "Code:",
      event.code,
      "Reason:",
      event.reason
    );
  };

  return socket;
};
const sentimentwebSocketConnection = (taskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/sentimental-analysis-task-status/${taskId}/`
  );

  socket.onopen = (event) => {
    console.log("Sentiment WebSocket connection established for task:", taskId);
  };

  socket.onmessage = (event) => {
    console.log("Sentiment WebSocket message received:", event.data);
    try {
      const data = JSON.parse(event.data);
      console.log("Parsed WebSocket data:", data);
      onMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      onError(error);
    }
  };

  socket.onerror = (error) => {
    console.error("Sentiment WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log(
      "Sentiment WebSocket connection closed for task:",
      taskId,
      "Code:",
      event.code,
      "Reason:",
      event.reason
    );
  };

  return socket;
};

const downloadPiiOutput = async (taskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-azure-pii-output/${taskId}/`,
      {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `pii_output_${taskId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

export const downloadSentimentOutput = async (taskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-sentimental-analysis/${taskId}/`,
      {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Sentiment_output_${taskId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

const csatwebSocketConnection = (csatTaskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/csat-task-status/${csatTaskId}/`
  );
  // ws://kakarotgenics-instance.centralus.azurecontainer.io:8000/ws/task-status/<project id>/

  socket.onopen = (event) => {
    console.log("WebSocket connection established", event);
  };

  socket.onmessage = (event) => {
    let percentData = JSON.parse(event.data);

    console.log("WebSocket message received", percentData);
    onMessage(JSON.parse(event.data));
  };

  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log("WebSocket connection closed", event.code, event.reason);
    // Implement reconnection logic here if needed
  };

  return socket;
};

const downloadCsatOutput = async (csatTaskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-csat-output/${csatTaskId}/`,
      {
        responseType: "blob", // Important for handling file downloads
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Set the content type for the response
        },
      }
    );

    // Create a blob from the response data
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `output-${csatTaskId}.xlsx`); // Set the desired file name
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the object URL
    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error; // Throw error to handle it in the calling function
  }
};
const textwebSocketConnection = (taskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/text-translation-task-status/${taskId}/`
  );

  socket.onopen = (event) => {
    console.log(
      "Text Translation WebSocket connection established for task:",
      taskId
    );
  };

  socket.onmessage = (event) => {
    console.log("Text Translation WebSocket message received:", event.data);
    try {
      const data = JSON.parse(event.data);
      console.log("Parsed WebSocket data:", data);
      onMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      onError(error);
    }
  };

  socket.onerror = (error) => {
    console.error("Text Translation WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log(
      "Text Translation WebSocket connection closed for task:",
      taskId,
      "Code:",
      event.code,
      "Reason:",
      event.reason
    );
  };

  return socket;
};
export const downloadTextOutput = async (taskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-text-translation/${taskId}/`,
      {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Text Tanslation_output_${taskId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

export const onelevelwebSocketConnection = (taskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/one-level-thematic-task-status/${taskId}/`
  );

  socket.onopen = (event) => {
    console.log("One Level connection established for task:", taskId);
  };

  socket.onmessage = (event) => {
    console.log("One Level WebSocket message received:", event.data);
    try {
      const data = JSON.parse(event.data);
      console.log("Parsed WebSocket data:", data);
      onMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      onError(error);
    }
  };

  socket.onerror = (error) => {
    console.error("One Level WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log(
      "One Level WebSocket connection closed for task:",
      taskId,
      "Code:",
      event.code,
      "Reason:",
      event.reason
    );
  };

  return socket;
};
export const downloadOneLevelOutput = async (taskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-one-level-thematic-output/${taskId}/`,
      {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `One Level_output_${taskId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
export const twolevelwebSocketConnection = (taskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/two-level-thematic-task-status/${taskId}/`
  );

  socket.onopen = (event) => {
    console.log("Two Level connection established for task:", taskId);
  };

  socket.onmessage = (event) => {
    console.log("Two Level WebSocket message received:", event.data);
    try {
      const data = JSON.parse(event.data);
      console.log("Parsed WebSocket data:", data);
      onMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      onError(error);
    }
  };

  socket.onerror = (error) => {
    console.error("Two Level WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log(
      "Two Level WebSocket connection closed for task:",
      taskId,
      "Code:",
      event.code,
      "Reason:",
      event.reason
    );
  };

  return socket;
};
export const downloadTwoLevelOutput = async (taskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-two-level-thematic-output/${taskId}/`,
      {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Two_Level_output_${taskId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

export const threelevelwebSocketConnection = (taskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/three-level-thematic-task-status/${taskId}/`
  );

  socket.onopen = (event) => {
    console.log("Three Level connection established for task:", taskId);
  };

  socket.onmessage = (event) => {
    console.log("Three Level WebSocket message received:", event.data);
    try {
      const data = JSON.parse(event.data);
      console.log("Parsed WebSocket data:", data);
      onMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      onError(error);
    }
  };

  socket.onerror = (error) => {
    console.error("Three Level WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log(
      "Three Level WebSocket connection closed for task:",
      taskId,
      "Code:",
      event.code,
      "Reason:",
      event.reason
    );
  };

  return socket;
};
export const downloadThreeLevelOutput = async (taskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-three-level-thematic-output/${taskId}/`,
      {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Three_Level_output_${taskId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
export const piigenaiwebSocketConnection = (taskId, onMessage, onError) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/pii-gen-ai-task-status/${taskId}/`
  );

  socket.onopen = (event) => {
    console.log("Pii Genai connection established for task:", taskId);
  };

  socket.onmessage = (event) => {
    console.log("Pii Genai WebSocket message received:", event.data);
    try {
      const data = JSON.parse(event.data);
      console.log("Parsed WebSocket data:", data);
      onMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      onError(error);
    }
  };

  socket.onerror = (error) => {
    console.error("Pii Genai WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log(
      "Pii Genai WebSocket connection closed for task:",
      taskId,
      "Code:",
      event.code,
      "Reason:",
      event.reason
    );
  };

  return socket;
};
export const downloadPiiGenAiOutput = async (taskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-pii-gen-ai-output/${taskId}/`,
      {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `PiiGenai_output_${taskId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
export const gibberishdetectorwebSocketConnection = (
  taskId,
  onMessage,
  onError
) => {
  const socket = new WebSocket(
    `wss://kakarotgenics-ai-backend.azurewebsites.net/ws/gibberish-detection-task-status/${taskId}/`
  );

  socket.onopen = (event) => {
    console.log("Gibberish Detector connection established for task:", taskId);
  };

  socket.onmessage = (event) => {
    console.log("Gibberish Detector WebSocket message received:", event.data);
    try {
      const data = JSON.parse(event.data);
      console.log("Parsed WebSocket data:", data);
      onMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      onError(error);
    }
  };

  socket.onerror = (error) => {
    console.error("Gibberish Detector WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    console.log(
      "Gibberish Detector WebSocket connection closed for task:",
      taskId,
      "Code:",
      event.code,
      "Reason:",
      event.reason
    );
  };

  return socket;
};
export const downloadGibberishDetectorOutput = async (taskId) => {
  try {
    const response = await axios.get(
      `${API_URL}download-gibberish-detection-output/${taskId}/`,
      {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `GibberishDetector_output_${taskId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

export {
  webSocketConnection,
  downloadCloudNativeOutput,
  piiwebSocketConnection,
  downloadPiiOutput,
  csatwebSocketConnection,
  downloadCsatOutput,
  sentimentwebSocketConnection,
  textwebSocketConnection,
};
