import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

const GraphicalAnalysis = ({ taskId, isTaskCompleted }) => {
  const [chartData, setChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isTaskCompleted && taskId) {
      fetchAnalysisData();
      fetchBarChartData();
    }
  }, [taskId, isTaskCompleted]);

  const fetchBarChartData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/bar-chart-visualization/",
        { task_id: taskId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data || !response.data.data) {
        throw new Error("No bar chart data received");
      }

      const processedData = {
        labels: response.data.data.map((item) => item.theme),
        values: response.data.data.map((item) => item.count),
        title: response.data.chart_title || "Frequency of Themes",
      };

      setBarChartData({
        series: [
          {
            name: "Theme Count",
            data: processedData.values,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 240,
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
              horizontal: false,
              columnWidth: "60%",
              distributed: true,
            },
          },
          colors: ["#4B88FD", "#FF9900", "#34A853", "#818CF8"],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val.toLocaleString();
            },
            style: {
              fontSize: "11px",
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: processedData.labels,
            labels: {
              style: {
                fontSize: "11px",
              },
            },
          },
          yaxis: {
            title: {
              text: "Count",
              style: {
                fontSize: "12px",
              },
            },
            labels: {
              style: {
                fontSize: "11px",
              },
            },
          },
          title: {
            text: processedData.title,
            align: "center",
            style: {
              fontSize: "14px",
            },
          },
        },
      });
    } catch (err) {
      console.error("Error fetching bar chart data:", err);
      setError("Failed to fetch bar chart data. Please try again later.");
    }
  };

  const fetchAnalysisData = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/pie-chart-visualization/",
        { task_id: taskId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data || !response.data.data) {
        throw new Error("No data received from the server");
      }

      const processedData = {
        labels: response.data.data.map((item) => item.brand),
        values: response.data.data.map((item) => item.count),
        title: response.data.chart_title || "Brand Distribution",
      };

      // Set pie chart data correctly
      setPieChartData({
        series: processedData.values,
        options: {
          chart: {
            type: "pie",
            height: 280,
          },
          labels: processedData.labels,
          colors: ["#4B88FD", "#FF9900", "#34A853", "#818CF8"],
          legend: {
            position: "bottom",
            fontSize: "11px",
            formatter: function (seriesName, opts) {
              return `${seriesName}: ${
                opts.w.globals.series[opts.seriesIndex]
              }`;
            },
          },
          title: {
            text: "Distribution by Percentage",
            align: "center",
            style: {
              fontSize: "14px",
            },
          },
          dataLabels: {
            style: {
              fontSize: "11px",
            },
          },
        },
      });

      setIsLoading(false);
    } catch (err) {
      console.error("Error details:", err);
      setError("Failed to fetch analysis data. Please try again later.");
      setIsLoading(false);
    }
  };

  if (!isTaskCompleted) {
    return (
      <div className="alert alert-info m-3">
        Please complete the analysis to view graphical insights.
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center m-3">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger m-3">{error}</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row g-3">
        {/* Bar Chart Column */}
        {barChartData && (
          <div className="col-md-6">
            <div className="card shadow-sm">
              <div className="card-body p-2">
                <ReactApexChart
                  options={barChartData.options}
                  series={barChartData.series}
                  type="bar"
                  height={280}
                />
              </div>
            </div>
          </div>
        )}

        {/* Pie Chart Column */}
        {pieChartData && (
          <div className="col-md-6">
            <div className="card shadow-sm">
              <div className="card-body p-2">
                <ReactApexChart
                  options={pieChartData.options}
                  series={pieChartData.series}
                  type="pie"
                  height={250}
                />
              </div>
            </div>
          </div>
        )}

        {/* Distribution Summary */}
        {pieChartData && (
          <div className="col-12">
            <div className="card shadow-sm">
              <div className="card-header py-2">
                <h6 className="card-title mb-0">Distribution Summary</h6>
              </div>
              <div className="card-body p-2">
                <ul className="list-group list-group-flush">
                  {pieChartData.options.labels.map((label, index) => (
                    <li key={index} className="list-group-item py-1">
                      <small>
                        <strong>{label}:</strong>{" "}
                        {pieChartData.series[index].toLocaleString()} (
                        {(
                          (pieChartData.series[index] /
                            pieChartData.series.reduce((a, b) => a + b, 0)) *
                          100
                        ).toFixed(1)}
                        %)
                      </small>
                    </li>
                  ))}
                  <li className="list-group-item py-1">
                    <small>
                      <strong>Total:</strong>{" "}
                      {pieChartData.series
                        .reduce((a, b) => a + b, 0)
                        .toLocaleString()}
                    </small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {!barChartData && !pieChartData && (
          <div className="col-12">
            <div className="alert alert-warning m-3">
              No analysis data available to display.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GraphicalAnalysis;
