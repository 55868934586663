// Header.js
import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Menu, X } from "lucide-react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchInputRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
    if (!isSearchActive) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchInputRef.current &&
      !searchInputRef.current.contains(event.target)
    ) {
      setIsSearchActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-white bg-white">
      <div className="container-fluid">
        {/* Desktop Brand */}
        <div className="brand-home-container">
          <NavLink className="navbar-brand gradient-text large-brand" to="/">
            TXTSense
          </NavLink>
        </div>

        {/* Mobile Header Bar */}
        <div className="mobile-header-bar">
          <button
            className="mobile-menu-button"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>

          <NavLink className="navbar-brand gradient-text large-brand" to="/">
            TXTSense
          </NavLink>

          <div className="mobile-icons">
            <button
              className="mobile-search-button"
              onClick={toggleSearch}
              aria-label="Search"
            >
              <SearchIcon />
            </button>
            <NavLink className="mobile-profile-link" to="/profile">
              <i className="fas fa-user-circle profile-icon"></i>
            </NavLink>
          </div>
        </div>

        {/* Desktop Navigation */}
        <div className={`desktop-nav ${isSearchActive ? "search-active" : ""}`}>
          {isSearchActive ? (
            <div className="search-container" ref={searchInputRef}>
              <SearchIcon className="search-icon" />
              <input
                type="text"
                className="search-input"
                placeholder="kakarotgenics.com"
                autoFocus
              />
              <button className="search-cancel-btn" onClick={toggleSearch}>
                Cancel
              </button>
            </div>
          ) : (
            <ul className="navbar-nav ms-auto mb-2">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Features
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link d-flex align-items-center"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleSearch();
                  }}
                >
                  Search
                  <SearchIcon className="ms-1" />
                </NavLink>
              </li>
              <li className="nav-item auth-button">
                <NavLink className="nav-link btn btn-login" to="login">
                  Log in
                </NavLink>
              </li>
              <li className="nav-item auth-button">
                <NavLink className="nav-link btn btn-signup" to="/register">
                  Sign up
                </NavLink>
              </li>
              <li className="nav-item profile-button">
                <NavLink className="nav-link" to="/profile">
                  <i className="fas fa-user-circle profile-icon"></i>
                </NavLink>
              </li>
            </ul>
          )}
        </div>

        {/* Mobile Navigation Dropdown */}
        <div className={`mobile-nav ${isOpen ? "show" : ""}`}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={toggleMenu}>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={toggleMenu}>
                Features
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="login" onClick={toggleMenu}>
                Log in
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/register" onClick={toggleMenu}>
                Sign up
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Mobile Search Overlay */}
        {isSearchActive && (
          <div className="mobile-search-overlay">
            <div className="search-container" ref={searchInputRef}>
              <SearchIcon className="search-icon" />
              <input
                type="text"
                className="search-input"
                placeholder="kakarotgenics.com"
                autoFocus
              />
              <button className="search-cancel-btn" onClick={toggleSearch}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
