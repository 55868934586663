import React, { useState, useEffect } from "react";
import "./Modules.css"; // Import your CSS file
import "bootstrap-icons/font/bootstrap-icons.css";
import Menu from "./Menu";

import { useNavigate, NavLink } from "react-router-dom";
import {
  getUserProjects,
  createProject,
  getAllUsers,
  deleteProject,
  getCloudNativeAccessUsers,
  getPiiDetectionAccessUsers,
  getAzureCsatAccessUsers,
} from "./apiService"; // Import API functions
import Select from "react-select";
import PopupMessage from "./PopupMessage";
import EmptyProjects from "./EmptyProjects";
import axios from "axios";

function Modules() {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({
    id: "",
    name: "",
    description: "",
    type: "",
  });
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [projectToView, setProjectToView] = useState(null);
  const [accessUsers, setAccessUsers] = useState([]);
  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState([]);
  const [selectedUsersToRemove, setSelectedUsersToRemove] = useState([]);

  // Function to fetch user projects from the backend
  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const response = await getUserProjects();
      console.log("API Response:", response);
      if (response && Array.isArray(response)) {
        setProjects(
          response.map((project) => ({
            ...project,
            id: project.project_id,
            project_name: project.project_name,
            project_description: project.description,
            type: project.project_type,
          }))
        );
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch projects when the component mounts
  useEffect(() => {
    fetchProjects();
    const fetchUsers = async () => {
      try {
        const users = await getAllUsers();
        setAllUsers(
          users.map((user) => ({
            value: user.id,
            label: `${user.username} (${user.email})`,
            profilePicture: user.profilePicture,
          }))
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const handleUserSelect = (selectedOptions) => {
    setNewProject({
      ...newProject,
      selectedUsers: selectedOptions.map((option) => option.value),
    });
  };

  const handleButtonClick = (projectType) => {
    switch (projectType) {
      case "cloud_native":
        navigate("/cloudnative-projects");
        break;
      case "pii_detection":
        navigate("/piidetector-projects");
        break;
      case "azure_csat":
        navigate("/azure-csat-projects");
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewProject({ ...newProject, [id]: value });
  };

  // Ensure that the project_type is sent correctly in the required format
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let projectType;
      switch (newProject.type) {
        case "Cloud Native":
          projectType = "cloud_native";
          break;
        case "PII Detection":
          projectType = "pii_detection";
          break;
        case "Azure CSAT":
          projectType = "azure_csat";
          break;
        default:
          projectType = "";
      }

      const createdProject = await createProject({
        ...newProject,
        type: projectType,
      });

      const projectWithDetails = {
        id: createdProject.project_id || newProject.id,
        project_name: newProject.name,
        project_description: newProject.description,
        project_type: newProject.type,
        type: projectType,
      };

      const imageUrl = getImageUrl(projectType);

      setProjects((prevProjects) => [
        ...prevProjects,
        { ...projectWithDetails, imageUrl },
      ]);

      setPopupMessage("Project Created Successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);

      setNewProject({ id: "", name: "", description: "", type: "" });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating project:", error);
      setPopupMessage("Failed to create project.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };

  const getImageUrl = (type) => {
    switch (type) {
      case "cloud_native":
        return "https://www.growthaccelerationpartners.com/wp-content/uploads/2023/10/The-Fundamental-Difference-Between-Cloud-and-Cloud-Native-Development.jpg";
      case "pii_detection":
        return "https://news-cdn.moonbeam.co/Detect-PII-and-Sensitive-Information-In-Real-Time-App-Developer-Magazine_i0dzgjm4.jpg";
      case "azure_csat":
        return "https://safe-connect.com/wp-content/uploads/2022/12/CSAT-Scan-1.png";
      default:
        return "";
    }
  };

  const handleDeleteClick = (project) => {
    setProjectToDelete(project);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (projectToDelete) {
      try {
        await deleteProject(projectToDelete.type);
        setProjects(projects.filter((p) => p.id !== projectToDelete.id));
        setPopupMessage("Project deleted successfully");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000); // Hide after 3 seconds
      } catch (error) {
        console.error("Error deleting project:", error);
        setPopupMessage("Failed to delete project");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 5000);
      }
    }
    setDeleteModalOpen(false);
    setProjectToDelete(null);
  };

  const handleViewClick = async (project) => {
    setProjectToView(project);
    try {
      let response;
      if (project.type === "cloud_native") {
        response = await getCloudNativeAccessUsers();
      } else if (project.type === "pii_detection") {
        response = await getPiiDetectionAccessUsers();
      } else if (project.type === "azure_csat") {
        response = await getAzureCsatAccessUsers();
      }
      setAccessUsers(response.access_users);
      setSelectedUsersToRemove([]);
      setViewModalOpen(true);
    } catch (error) {
      console.error("Error fetching access users:", error);
      setPopupMessage("Failed to fetch access users");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };
  const toggleCreateModal = () => {
    setIsModalOpen(!isModalOpen);
    document.body.classList.toggle("modal-open");
  };

  const handleAddUsersClick = async (project) => {
    setProjectToView(project);
    try {
      let response;
      if (project.type === "cloud_native") {
        response = await axios.get(
          "https://kakarotgenics-django-intermediate.azurewebsites.net/api/fetch-available-cloud-native-users/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
      } else if (project.type === "pii_detection") {
        response = await axios.get(
          "https://kakarotgenics-django-intermediate.azurewebsites.net/api/fetch-available-pii-detection-users",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
      } else if (project.type === "azure_csat") {
        response = await axios.get(
          "https://kakarotgenics-django-intermediate.azurewebsites.net/api/fetch-available-azure-csat-users",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
      }
      setAvailableUsers(
        response.data.available_users.map((user) => ({
          value: user.id,
          label: `${user.username} (${user.email})`,
        }))
      );
      setAddUsersModalOpen(true);
    } catch (error) {
      console.error("Error fetching available users:", error);
      setPopupMessage("Failed to fetch available users");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };

  const handleAddUsers = async () => {
    try {
      let endpoint;
      switch (projectToView.type) {
        case "cloud_native":
          endpoint =
            "https://kakarotgenics-django-intermediate.azurewebsites.net/api/add-cloud-native-users/";
          break;
        case "pii_detection":
          endpoint =
            "https://kakarotgenics-django-intermediate.azurewebsites.net/api/add-pii-detection-users/";
          break;
        case "azure_csat":
          endpoint =
            "https://kakarotgenics-django-intermediate.azurewebsites.net/api/add-azure-csat-users/";
          break;
        default:
          throw new Error("Invalid project type");
      }

      const formData = new FormData();
      selectedUsersToAdd.forEach((user) => {
        formData.append("user_ids", user.value);
      });

      const response = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setPopupMessage("Users added successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
      setAddUsersModalOpen(false);
      setSelectedUsersToAdd([]);
    } catch (error) {
      if (error.response && error.response.data.error) {
        setPopupMessage(error.response.data.error);
      } else {
        setPopupMessage("Failed to add users");
      }
      setShowPopup(true);
    }
  };

  const handleUserRemove = (userId) => {
    // Add logic to remove the user
    setSelectedUsersToRemove([userId]);
    handleRemoveUsers();
  };

  const handleRemoveUsers = async () => {
    if (selectedUsersToRemove.length === 0) return;

    try {
      let endpoint;
      switch (projectToView.type) {
        case "cloud_native":
          endpoint =
            "https://kakarotgenics-django-intermediate.azurewebsites.net/api/remove-cloud-native-users/";
          break;
        case "pii_detection":
          endpoint =
            "https://kakarotgenics-django-intermediate.azurewebsites.net/api/remove-pii-detection-users/";
          break;
        case "azure_csat":
          endpoint =
            "https://kakarotgenics-django-intermediate.azurewebsites.net/api/remove-azure-csat-users/";
          break;
        default:
          throw new Error("Invalid project type");
      }

      const formData = new FormData();
      selectedUsersToRemove.forEach((userId) => {
        formData.append("user_ids", userId);
      });

      await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setAccessUsers(
        accessUsers.filter((user) => !selectedUsersToRemove.includes(user.id))
      );
      setSelectedUsersToRemove([]);
      setPopupMessage("User removed successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    } catch (error) {
      console.error("Error removing users:", error);
      setPopupMessage("Failed to remove user");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    }
  };

  return (
    <section>
      <Menu />
      <nav className="navbar navbar-light bg-body-tertiary custom-navbar">
        <div className="container-fluid">
          <NavLink className="navbar-brand gradient-text" to="#">
            Projects
          </NavLink>
        </div>
      </nav>
      <button
        className="projects-btn-hover color-3"
        onClick={toggleCreateModal}
      >
        Create Project
      </button>
      {isLoading ? (
        <div className="text-center" style={{ marginTop: "300px" }}>
          <div
            className="spinner-border"
            role="status"
            style={{
              color: "rgb(105, 0, 225)",
              width: "2rem",
              height: "2rem",
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : projects.length > 0 ? (
        <div className="Module-cards-container">
          {projects.map((project) => (
            <div className="home-card" key={project.project_id}>
              <div
                className="home-card-img"
                style={{ backgroundImage: `url(${getImageUrl(project.type)})` }}
              >
                <div className="home-overlay">
                  <div className="home-overlay-content"></div>
                </div>
                <div className="icon-container">
                  <div
                    className="view-icon"
                    onClick={() => handleViewClick(project)}
                    title="View Users"
                  >
                    <i className="bi bi-eye"></i>
                  </div>
                  <div
                    className="add-users-icon me-5"
                    onClick={() => handleAddUsersClick(project)}
                    title="Add Users"
                  >
                    <i className="bi bi-person-fill-add"></i>
                  </div>
                  <div
                    className="delete-icon "
                    onClick={() => handleDeleteClick(project)}
                    title="Delete Project"
                  >
                    <i className="bi bi-trash"></i>
                  </div>
                </div>
              </div>
              <div className="home-card-content">
                <h6>{project.project_name}</h6>
                <p>{project.project_description}</p>
                <button
                  className="modules btn modules btn--block modules card__btn"
                  onClick={() => handleButtonClick(project.type)}
                >
                  Create Task
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyProjects />
      )}

      {/* Modal for creating a new project */}
      <div
        className={`moduleModal modal fade ${isModalOpen ? "show" : ""}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!isModalOpen}
      >
        <div className="moduleModalDialog modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New Project
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={toggleCreateModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="id" className="col-form-label">
                    Project ID:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="id"
                    value={newProject.id}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Project Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={newProject.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="col-form-label">
                    Project Description:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    value={newProject.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="type" className="col-form-label">
                    Project Type:
                  </label>
                  <select
                    className="form-select"
                    id="type"
                    value={newProject.type}
                    onChange={(e) =>
                      setNewProject({ ...newProject, type: e.target.value })
                    }
                    required
                  >
                    <option value="">Select project type</option>
                    <option value="Cloud Native">Cloud Native</option>
                    <option value="PII Detection">PII Detection</option>
                    <option value="Azure CSAT">Azure CSAT</option>
                  </select>
                </div>
                <Select
                  isMulti
                  name="users"
                  options={allUsers}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleUserSelect}
                  placeholder="Select users..."
                  noOptionsMessage={() => "No users found"}
                  formatOptionLabel={(option) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={
                          option.profilePicture
                            ? `data:image/png;base64,${option.profilePicture}`
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                        }
                        alt="Profile"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginRight: "10px",
                          objectFit: "cover",
                        }}
                      />
                      <div>
                        <div>{option.label.split(" (")[0]}</div>
                        <div style={{ fontSize: "0.8em", color: "#666" }}>
                          {option.label.split(" (")[1].slice(0, -1)}
                        </div>
                      </div>
                    </div>
                  )}
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }),
                    control: (provided) => ({
                      ...provided,
                      minHeight: "50px",
                    }),
                  }}
                />
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-cancel-projects"
                    onClick={toggleCreateModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn-create-project">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {deleteModalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <div className="modal-header">
              <h3>Delete Project</h3>
              <button
                className="close-btn"
                onClick={() => setDeleteModalOpen(false)}
              >
                &times;
              </button>
            </div>
            <p>Are you sure you want to delete this project?</p>
            <p className="warning">This action cannot be undone.</p>
            <div className="button-container">
              <button
                className="cancel-btn"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button className="delete-btn" onClick={handleDeleteConfirm}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {viewModalOpen && (
        <div className="view-modal">
          <div
            className="view-modal-content"
            style={{
              width: "400px",
              maxWidth: "90%",
              background: "white",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="modal-header"
              style={{ padding: "15px 20px", borderBottom: "none" }}
            >
              <h3 style={{ margin: 0, fontSize: "16px", fontWeight: "600" }}>
                People ({accessUsers.length})
              </h3>
              <button
                className="close-btn"
                onClick={() => setViewModalOpen(false)}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "20px",
                  color: "#666",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
            </div>
            <div
              className="user-list"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                padding: "0 20px 20px",
              }}
            >
              {accessUsers.map((user) => (
                <div
                  key={user.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "8px 0",
                    marginBottom: "4px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                      alt="Profile"
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        marginRight: "12px",
                        objectFit: "cover",
                      }}
                    />
                    <div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#333",
                          fontWeight: "400",
                        }}
                      >
                        {user.username}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#666",
                          marginTop: "2px",
                        }}
                      >
                        {user.email}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleUserRemove(user.id)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#666",
                      fontSize: "20px",
                      padding: "4px 8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      className="bi bi-x"
                      style={{ fontSize: "16px", color: "#dc3545" }}
                    ></i>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {addUsersModalOpen && (
        <div className="add-users-modal">
          <div className="add-users-modal-content">
            <div className="modal-header">
              <h3>Add Users</h3>
              <button
                className="close-btn"
                onClick={() => setAddUsersModalOpen(false)}
              >
                &times;
              </button>
            </div>
            <Select
              isMulti
              name="users"
              options={availableUsers}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedUsersToAdd}
              value={selectedUsersToAdd}
              placeholder="Select users to add..."
              formatOptionLabel={(option) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                    alt="Profile"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                      objectFit: "cover",
                    }}
                  />
                  <div>
                    <div>{option.label.split(" (")[0]}</div>
                    <div style={{ fontSize: "0.8em", color: "#666" }}>
                      {option.label.split(" (")[1].slice(0, -1)}
                    </div>
                  </div>
                </div>
              )}
              styles={{
                option: (provided) => ({
                  ...provided,
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }),
                control: (provided) => ({
                  ...provided,
                  minHeight: "50px",
                }),
              }}
            />
            <div className="button-container">
              <button
                className="cancel-btn"
                onClick={() => setAddUsersModalOpen(false)}
              >
                Cancel
              </button>
              <button className="add-btn" onClick={handleAddUsers}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </section>
  );
}

export default Modules;
