import axios from "axios";

const API_URL =
  "https://kakarotgenics-django-intermediate.azurewebsites.net/api/";

export const createAzureCsatTask = async (taskData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();

    // Append the required fields for the task
    formData.append("task_name", taskData.name);
    formData.append("task_description", taskData.description);

    const response = await axios.post(
      `${API_URL}create-azure-csat-task/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating cloud-native task:", error);
    throw error;
  }
};

export const getAzureCsatTasks = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_URL}get-azure-csat-tasks/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.tasks.map((task) => ({
      id: task.task_id,
      project_name: task.task_name,
      project_description: task.task_description,
      created_by: task.created_by,
      created_time: task.created_at || new Date().toISOString(),
    }));
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};

export const deleteAzureCsatTask = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);

    const response = await axios.post(
      `${API_URL}delete-azure-csat-task/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting cloud native task:", error);
    throw error;
  }
};

export const getAzureCsatTasksDetails = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}get-azure-csat-task-detail/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadVerbatimFile = async (taskId, file) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("verbatim_file", file);

    const response = await axios.post(
      `${API_URL}upload-verbatim-file-azure-csat/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading verbatim file:", error);
    throw error;
  }
};

export const selectSheetFromVerbatim = async (taskId, sheetName) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("sheet_name", sheetName);

    const response = await axios.post(
      `${API_URL}select-sheet-from-verbatim-azure-csat/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error selecting sheet from verbatim:", error);
    throw error;
  }
};

export const selectColumnFromVerbatim = async (taskId, columnName) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("selected_column", columnName);

    const response = await axios.post(
      `${API_URL}select-column-from-verbatim-azure-csat/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error selecting column from verbatim:", error);
    throw error;
  }
};
export const uploadcodeframeFile = async (taskId, file) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("codeframe_file", file);

    const response = await axios.post(
      `${API_URL}upload-codeframe-file-azure-csat/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading Codeframe file:", error);
    throw error;
  }
};

export const selectSheetFromCodeframe = async (taskId, sheetName) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("sheet_name", sheetName);

    const response = await axios.post(
      `${API_URL}select-sheet-from-codeframe-azure-csat/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error selecting sheet from Codeframe:", error);
    throw error;
  }
};

export const runAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Project ID is required to run analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");

    const response = await axios.post(
      `${API_URL}run-csat-analysis/`,
      { task_id: taskId }, // Pass project ID in the body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running analysis:", error);
    throw error;
  }
};
export const getTaskStatus = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}get-task-status/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting task status:", error);
    throw error;
  }
};

export const runTextAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Project ID is required to run analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");

    const response = await axios.post(
      `${API_URL}/run-text-translation-task/`,
      { task_id: taskId }, // Pass project ID in the body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running analysis:", error);
    throw error;
  }
};
export const runOneLevelAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Project ID is required to run analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");

    const response = await axios.post(
      `${API_URL}/run-level1-thematic-analysis-task/`,
      { task_id: taskId }, // Pass project ID in the body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running analysis:", error);
    throw error;
  }
};

export const runTwoLevelAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Project ID is required to run analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");

    const response = await axios.post(
      `${API_URL}/run-level2-thematic-analysis-task/`,
      { task_id: taskId }, // Pass project ID in the body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running analysis:", error);
    throw error;
  }
};
export const runThreeLevelAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Project ID is required to run analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");

    const response = await axios.post(
      `${API_URL}/run-level3-thematic-analysis-task/`,
      { task_id: taskId }, // Pass project ID in the body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running analysis:", error);
    throw error;
  }
};
export const runPiiGenAiAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Project ID is required to run analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");

    const response = await axios.post(
      `${API_URL}/run-pii-genai-task/`,
      { task_id: taskId }, // Pass project ID in the body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running analysis:", error);
    throw error;
  }
};
export const runGibberishDetectorAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Project ID is required to run analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");

    const response = await axios.post(
      `${API_URL}/run-gibberish-detector-task/`,
      { task_id: taskId }, // Pass project ID in the body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running analysis:", error);
    throw error;
  }
};
