import React, { useState, useEffect } from "react";
import { X, UserPlus } from "lucide-react";
import "./ParticipantsModal.css";
import Select from "react-select";
import PopupMessage from "./PopupMessage"; // Import the PopupMessage component

const UserManagement = ({ isOpen, onClose, taskId }) => {
  const [currentUsers, setCurrentUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState([]);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  useEffect(() => {
    if (isOpen && taskId) {
      fetchCurrentUsers();
    }
  }, [isOpen, taskId]);

  const showPopupMessage = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 3000);
  };
  const fetchCurrentUsers = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new FormData();
      formData.append("task_id", taskId);

      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/view-sentiment-users/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const data = await response.json();
      setCurrentUsers(data.users_with_access || []);
      showPopupMessage("Fetched Users Successfully");
    } catch (error) {
      console.error("Error fetching current users:", error);
      showPopupMessage("Error fetching users");
    }
  };

  const fetchAvailableUsers = async () => {
    setIsLoadingModal(true);
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new FormData();
      formData.append("task_id", taskId);

      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/fetch-available-sentiment-users/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const data = await response.json();
      setAvailableUsers(data.available_users || []);
    } catch (error) {
      console.error("Error fetching available users:", error);
    } finally {
      setIsLoadingModal(false);
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      const formData = new FormData();
      formData.append("user_ids", userId);
      formData.append("task_id", taskId);

      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/remove-sentiment-users/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        await fetchCurrentUsers();
        showPopupMessage("User has been removed");
      } else {
        const errorData = await response.json();
        console.error("Error removing user:", errorData);
        showPopupMessage("Error removing user");
      }
    } catch (error) {
      console.error("Error removing user:", error);
      showPopupMessage("Error removing user");
    }
  };

  const handleAddUser = async (userId) => {
    try {
      const formData = new FormData();
      formData.append("user_ids", userId);
      formData.append("task_id", taskId);

      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/add-sentiment-users/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        await fetchCurrentUsers();
        showPopupMessage("Users Added Successfully");
        // await fetchAvailableUsers();
        // showPopupMessage("Users Added Successfully");
      } else {
        const errorData = await response.json();
        console.error("Error adding user:", errorData);
        showPopupMessage("Error adding user");
      }
    } catch (error) {
      console.error("Error adding user:", error);
      showPopupMessage("Error adding user");
    }
  };

  const handleShowAddUsers = async () => {
    await fetchAvailableUsers();
    setShowAddUsersModal(true);
  };

  const handleAddUsers = async () => {
    try {
      for (const user of selectedUsersToAdd) {
        await handleAddUser(user.value);
      }
      setShowAddUsersModal(false);
      setSelectedUsersToAdd([]);
    } catch (error) {
      console.error("Error adding users:", error);
    }
  };

  if (!isOpen) return null;

  const selectOptions = availableUsers.map((user) => ({
    value: user.id,
    label: `${user.username} (${user.email})`,
    userData: user,
  }));

  const LoadingSpinner = () => (
    <div className="text-center" style={{ marginTop: "20px" }}>
      <div
        className="spinner-border"
        role="status"
        style={{
          color: "rgb(105, 0, 225)",
          width: "2rem",
          height: "2rem",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
    <div className="modals-container">
      <div className="modals-wrapper">
        {/* Participants Modal */}
        <div className="participants-modal">
          <div className="modal-header">
            <h5 className="modal-title">People ({currentUsers.length})</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body p-0">
            {currentUsers.length > 0 ? (
              <div className="users-list-container">
                {currentUsers.map((user) => (
                  <div key={user.id} className="user-item">
                    <div className="user-info">
                      <div className="user-avatar">
                        {user.username?.[0]?.toUpperCase()}
                      </div>
                      <div className="user-text">
                        <p className="user-name">{user.username}</p>
                        <small className="user-email">{user.email}</small>
                      </div>
                    </div>
                    <button
                      onClick={() => handleRemoveUser(user.id)}
                      className="remove-user-btn"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty-state">No users added yet</div>
            )}

            <div className="add-people-section">
              <button
                onClick={handleShowAddUsers}
                className="add-people-btn"
                disabled={isLoadingModal}
              >
                <UserPlus size={16} />
                <span>Add people</span>
              </button>
            </div>
          </div>
        </div>

        {/* Add Users Modal - Only show when loading or ready */}
        {(showAddUsersModal || isLoadingModal) && (
          <div className="add-users-modal">
            <div className="modal-header">
              <h3>Add Users</h3>
            </div>
            {isLoadingModal ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="select-container">
                  <Select
                    isMulti
                    options={selectOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={selectedUsersToAdd}
                    onChange={setSelectedUsersToAdd}
                    placeholder="Enter a name or email"
                    formatOptionLabel={(option) => (
                      <div className="user-option">
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            backgroundColor: "#f0f0f0",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexShrink: 0,
                          }}
                        >
                          {option.userData?.username?.[0]?.toUpperCase()}
                        </div>
                        <div className="user-option-details">
                          <div className="user-option-name">
                            {option.userData?.username}
                          </div>
                          <div className="user-option-email">
                            {option.userData?.email}
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="button-container">
                  <button
                    className="cancel-btn"
                    onClick={() => setShowAddUsersModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="add-btn"
                    onClick={handleAddUsers}
                    disabled={selectedUsersToAdd.length === 0}
                  >
                    Add
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default UserManagement;
