import React, { useState, useEffect } from "react";
import { changePassword } from "./apiService"; // Assuming you have an API service for changing the password
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ChangePassword.css"; // Add your custom CSS for modal styling if needed

function ChangePassword({ showModal, closeModal }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 425);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      toast.error("New passwords do not match!");
      return;
    }

    try {
      await changePassword({
        current_password: currentPassword,
        new_password1: newPassword,
        new_password2: confirmNewPassword,
      });
      toast.success("Password changed successfully!");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      closeModal(); // Close the modal on successful password change
    } catch (error) {
      toast.error("Error changing password");
    }
  };

  if (!showModal) return null; // Render nothing if the modal should not be shown

  return (
    <div
      className="modal show"
      tabIndex={-1}
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header ">
            <button
              type="button"
              className="close close-button"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <h4 className="modal-title">Change Password</h4>
          </div>
          <div className="modal-body">
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="currentPassword" className="control-label">
                  Current Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword" className="control-label">
                  New Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <p className="password-requirements">
                  Your password must be at least 8 characters long, contain at
                  least one number, one uppercase letter (A-Z), and one
                  lowercase letter (a-z).
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="confirmNewPassword" className="control-label">
                  Confirm Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn-save-changes"
                  style={{ float: "right", marginLeft: 15 }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn-cancel-password"
                  style={{ float: "right" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
