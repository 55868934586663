// TokenValidator.js
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// Function to check if token is expired
export const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

// Function to get clean path from current location
const getCleanPath = () => {
  // Remove the login?next= part if it exists and decode the remaining path
  const path = window.location.hash.replace(/^[/#]+/, "");
  if (path.startsWith("login?next=")) {
    return decodeURIComponent(path.replace("login?next=", ""));
  }
  return path;
};

// Setup axios interceptor for handling 401 responses
export const setupAxiosInterceptors = (navigate) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        // Clear tokens from localStorage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        // Get clean current path
        const currentPath = getCleanPath();

        // Navigate to login with the return URL
        navigate(`/login?next=${currentPath}`);
      }
      return Promise.reject(error);
    }
  );
};

// Function to validate token and redirect if needed
export const validateTokenAndRedirect = async (navigate) => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!accessToken || !refreshToken || isTokenExpired(accessToken)) {
    // Clear tokens
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    // Get clean current path
    const currentPath = getCleanPath();

    // Navigate to login with the return URL
    navigate(`/login?next=${currentPath}`);
    return false;
  }

  return true;
};
