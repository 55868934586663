import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Welcome.css";
import Menu from "./Menu";

function Welcome() {
  const location = useLocation();
  const userName = location.state?.userName;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 425);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Menu />
      <div className="welcome-background">
        <div className="welcome-message">
          <span>Hello {userName}</span>
          <p>Welcome to KakarotGenics{isMobile ? "" : " !"}</p>
        </div>
      </div>
    </>
  );
}

export default Welcome;
