import React from "react";

const CostEstimationPopup = ({
  costEstimation,
  onClose,
  onConfirm,
  agreementChecked,
  setAgreementChecked,
}) => {
  // Format number to fixed decimal places and add commas for thousands
  const formatNumber = (num, decimalPlaces = 2) => {
    if (num === undefined || num === null || isNaN(num)) return "0.00";
    return Number(num)
      .toFixed(decimalPlaces)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Safely access nested properties
  const data = costEstimation || {};

  return (
    <div className="cost-estimation-popup">
      <div className="cost-estimation-content">
        <h4>Cost Estimation Report</h4>

        <div className="estimation-details">
          <h6>Verbatim Analysis</h6>
          <ul>
            <li>
              Total Verbatims: {formatNumber(data.total_number_of_verbatims, 0)}
            </li>
            <li>
              Total Words: {formatNumber(data.total_words_in_all_verbatims, 0)}
            </li>
            <li>
              Average Tokens per Verbatim:{" "}
              {formatNumber(data.average_tokens_per_verbatim, 1)}
            </li>
          </ul>

          <h6>Token Usage</h6>
          <ul>
            <li>
              Input Tokens per Verbatim:{" "}
              {formatNumber(data.input_token_size_per_verbatim, 1)}
            </li>
            <li>
              Output Tokens per Verbatim:{" "}
              {formatNumber(data.output_token_size_per_verbatim, 1)}
            </li>
          </ul>

          <h6>Cost Breakdown</h6>
          <ul>
            <li>
              Input Token Cost per Verbatim: $
              {formatNumber(data.total_input_token_cost_per_verbatim, 2)}
            </li>
            <li>
              Output Token Cost per Verbatim: $
              {formatNumber(data.total_output_token_cost_per_verbatim, 2)}
            </li>
            <li>
              Total Cost per Verbatim: $
              {formatNumber(data.total_cost_per_verbatim, 6)}
            </li>
            <li className="font-bold">
              Total Estimated Cost: ${formatNumber(data.final_total_cost, 2)}
            </li>
            <li className="font-bold">
              Total Cost (INR): ₹{formatNumber(data.final_total_cost_inr, 2)}
            </li>
          </ul>
        </div>

        <div className="agreement-checkbox">
          <label>
            <input
              type="checkbox"
              checked={agreementChecked}
              onChange={(e) => setAgreementChecked(e.target.checked)}
            />
            <span>
              I agree to the estimated cost of $
              {formatNumber(data.final_total_cost, 6)}
              (INR: ₹{formatNumber(data.final_total_cost_inr, 2)})
            </span>
          </label>
        </div>

        <div className="popup-buttons">
          <button onClick={onClose} className="popup-cancel-button">
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={!agreementChecked}
            className="popup-run-button"
            style={{ opacity: agreementChecked ? 1 : 0.5 }}
          >
            Run
          </button>
        </div>
      </div>

      <style jsx>{`
        .cost-estimation-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .cost-estimation-content {
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          width: 80%;
          max-width: 600px;
          max-height: 80vh;
          overflow-y: auto;
        }

        .estimation-details {
          margin-bottom: 20px;
        }

        .estimation-details h6 {
          margin-top: 15px;
          margin-bottom: 10px;
          font-weight: 600;
        }

        .estimation-details ul {
          padding-left: 20px;
          margin-bottom: 15px;
          list-style-type: none;
        }

        .estimation-details li {
          margin-bottom: 8px;
          font-size: 14px;
          line-height: 1.5;
        }

        .agreement-checkbox {
          margin: 15px 0;
          display: flex;
          align-items: center;
        }

        .agreement-checkbox input[type="checkbox"] {
          margin-right: 10px;
        }

        .agreement-checkbox label {
          user-select: none;
          font-size: 16px;
          display: flex;
          align-items: center;
        }

        .popup-buttons {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          margin-top: 20px;
        }

        .popup-run-button {
          padding: 15px;
          background-color: rgb(146, 98, 201);
          color: white;
          font-size: 15px;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          text-align: center;
          width: 15%;
          height: 50px;
          font-weight: 500;
          transition: opacity 0.2s;
        }

        .popup-run-button:disabled {
          cursor: not-allowed;
        }

        .popup-cancel-button {
          padding: 15px;
          font-size: 15px;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          text-align: center;
          width: 20%;
          height: 47px;
          font-weight: 500;
          background-color: #f0f0f0;
        }

        h4 {
          font-size: 20px;
          text-align: center;
          font-weight: bold;
          background: linear-gradient(to right, #703eb6, #703eb6);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
};

export default CostEstimationPopup;
